<template>
  <div class="metadata-options">
    <v-card-actions class="pa-0">
      <page-title :title="title" />
      <v-spacer></v-spacer>
      <!-- <AddButton
        :create-function="openDialog"
        :icon="'mdi-view-grid-plus-outline'"
        :title="'ADD NEW'"
      /> -->
    </v-card-actions>

    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :value="selected"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        :single-expand="true"
        :no-data-text="'Data not availble in this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <template v-slot:top>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchCategories"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Data'"
                :append-icon="''"
              />
            </v-col>
          </v-card-title>
        </template>
        <!--<template v-slot:item.actions="{ item }">
          <v-icon
            color="green"
            class="mr-2"
            outlined
            fab
            @click="openDialog(item)"
          >
            mdi-pencil-box-outline
          </v-icon>
          <v-icon color="deep-orange" @click="openDeleteDialog(item.id)">
            mdi-trash-can-outline
          </v-icon>
        </template>-->
        <template v-slot:item="{ item, isExpanded, expand }">
          <tr>
            <td>{{ item.index }}</td>
            <td>{{ item.name }}</td>
            <td>{{ textTruncate(item.code, 30) }}</td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    class="mr-2"
                    @click="expand(!isExpanded)"
                  >
                    mdi-format-list-bulleted-type
                  </v-icon>
                </template>
                <span>List of Metadata Options</span>
              </v-tooltip>
            </td>
            <td>
              <ActionsButtons
                :update-function="openDialog"
                :delete-function="openDeleteDialog"
                :formData="item"
                :updateIcon="' mdi-pencil-box-outline'"
                :deleteIcon="'mdi-trash-can-outline'"
                :updateButtonColor="'primary'"
                :deleteButtonColor="'red'"
              />
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="pb-5">
            <div class="ml-5 pt-5">
              <b>List of options linked to:</b>
              {{ item.name }}

              <!--Code:
              <em>
                <b>{{ item.code }}</b>
              </em>-->
              <v-card outlined flat max-width="80%">
                <v-data-table
                  :headers="headerOptions"
                  :items="item.options"
                  :items-per-page="item.options ? item.options.length : 20"
                  hide-default-footer
                  dense
                ></v-data-table>
              </v-card>
            </div>
          </td>
        </template>
      </v-data-table>
      <floating-button
        :create-function="openDialog"
        :icon="'mdi-plus'"
        :title="''"
      />
    </v-card>
    <!-- <CreateButton
      :create-function="openDialog"
      :icon="' mdi-view-grid-plus-outline'"
    />-->
    <OptionDialog
      :closeDialog="closeDialog"
      :isEditing="isEditing"
      :formData="formData"
      :dialog="dialog"
      :save="save"
      :loading="loading"
      :metadataOptions="metadataOptions"
      :searchOptions="searchOptions"
    />
    <ConfirmDialog
      :reject-function="closeDeleteDialog"
      :accept-function="deleteObject"
      :item="item"
      :isOpen="isOpen"
      :title="'Are you sure you want to delete?'"
      :loading="loading"
    />
  </div>
</template>

<script>
import OptionDialog from "./Forms/CategoryDialog";
import { get, create, update, remove } from "./services/data-category-service";
import { get as fetchOtions } from "./services/metadata-option-service";
import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    OptionDialog,
  },
  name: "MetaOption",
  data() {
    return {
      metadataOptions: [],
      selected: [],
      gender: [],
      datas: [],
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      params: {},
      item: null,
      items: [],
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Code",
          align: "start",
          sortable: false,
          value: "code",
          class: "primary--text",
        },
        {
          text: "Options",
          align: "start",
          sortable: false,
          value: "options",
          class: "primary--text",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
      headerOptions: [
        {
          text: "Metadata Options",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Metadata Code",
          align: "start",
          sortable: false,
          value: "code",
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    title() {
      return "Manage Data Categories";
    },
    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init(this.params);
      },
    },
  },

  methods: {
    init(params) {
      this.loading = true;
      get({
        ...params,
      })
        .then((response) => {
          this.loading = false;
          // console.log('resp', response.data[0].total)
          this.params.total = response.data[0].total;
          this.items = response.data[0]
            ? response.data[0].data
                .map((option) => ({
                  ...option,
                }))
                .sort(function(a, b) {
                  if (a > b) return 1;
                  return -1;
                })
                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    textTruncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      this.getOptions();
    },

    closeDeleteDialog() {
      this.item = null;
      this.isOpen = false;
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    deleteObject() {
      this.loading = true;
      remove(this.item)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },

    searchCategories(optionName) {
      this.init({
        search: optionName ? optionName : null,
        size: this.params.size,
        total: this.params.total,

        //...this.params,
      });
    },
    searchOptions(data) {
      this.getOptions({
        search: data ? data : null,
      });
    },
    save(data) {
      this.loading = true;
      if (data.id) {
        update(data)
          .then(() => {
            this.finishDataActions();
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      } else {
        create(data)
          .then(() => {
            console.log("created");
            this.finishDataActions();
            //this.init(this.params)
          })
          .catch((err) => {
            if (err) {
              this.loading = false;
            }
          });
      }
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },
    getOptions(params) {
      fetchOtions({ ...params, size: 1000 }).then((response) => {
        // console.log(response.data[0].data)
        this.metadataOptions = response.data[0].data;
        //this.datas.push(...this.metadataOptions)
      });
    },
    getGenderList() {
      this.$store
        .dispatch("DataCategory/getCategoryOptions", "GENDER")
        .then((response) => {
          this.gender = response;
        });
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init(this.params);
      },
      update() {
        this.loading = true;
        this.init(this.params);
      },
      deep: true,
    },
  },
  created() {
    this.getOptions();
  },
  mounted() {
    this.getGenderList();
  },
};
</script>

<style scoped></style>
